<template>
	<div class="task-table position-relative w-100 h-100 px-4 py-3 d-flex flex-column overflow-hidden">
		<table-summary title="Task" filter="taskNo" sort="dueAt"
		               v-bind:order="-1"
		               v-bind:fields="fields"
		               v-bind:data-array="tasks"
		               v-on:add-new="addTask"
		               v-on:row-clicked="navigate($event)">
        </table-summary>
	</div>
</template>

<script>
import TableSummary from "@/components/functional/table/TableSummary";
import {format} from "date-fns";

export default {
	name: "TaskTable",

	components: {
		TableSummary,
	},

	data() {
		return {
			fields: [
				{ display: "Task No.", key: "taskNo", classBinding: ["f-4"] },
				{ display: "Status", key: "status", classBinding: [] },
				{ display: "Stage", key: "stage", classBinding: ["f-2"] },
				{ display: "Client", key: "client", classBinding: ["f-5"] },
				{ display: "Quotation Request", key: "quotationRequests", classBinding: [] },
				{ display: "Supplier Quote", key: "supplierQuotations", classBinding: [] },
				{ display: "PO", key: "purchaseOrders", classBinding: [] },
				{ display: "Sales Quote", key: "salesQuotations", classBinding: [] },
				{ display: "Sales Orders", key: "salesOrders", classBinding: [] },
				{ display: "Campaigns", key: "campaigns", classBinding: ["f-2"] },
				{ display: "Due Date", key: "dueAt", classBinding: ["f-2"] },
				{ display: "Handler", key: "handler", classBinding: ["f-4"] },
			],
		}
	},

	computed: {
		tasks() {
			return this.$store.state.contents.tasks.map(task => {
				const obj = {...task};
				const client = this.$store.state.contents.clients.find(({ _id }) => _id === task.client);
				const handler = this.$store.state.contents.staffs.find(({ _id }) => _id === task.handler);
				obj.client = client ? `${client.name} (${client.clientNo})` : "N/A";
				obj.quotationRequests = task.quotationRequests.length;
				obj.supplierQuotations = task.supplierQuotations.length;
				obj.purchaseOrders = task.purchaseOrders.length;
				obj.salesQuotations = task.salesQuotations.length;
				obj.salesOrders = task.salesOrders.length;
				obj.campaigns = task.campaigns.length;
				obj.dueAt = task.dueAt ? format(new Date(task.dueAt), "yyyy-MM-dd") : "N/A";
				obj.handler = handler ? `${handler.preferredName} ${handler.lastName}` : "N/A";
				return obj;
			});
		},
	},

	methods: {
		async addTask() {
			await this.$router.push("/project/task/add");
		},

		async navigate(dataObj) {
			await this.$router.push(`/project/task/${dataObj._id}`);
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
